import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'

import { Column, Row, Button, Text, NumberInput, PasswordInput, Link } from '../src/components'

import { useAuth } from 'hooks'
import { LoginResolver, setModalCampaing, checkIsInvestor, checkIsCompletedInvestor } from 'helpers'
import { useUser } from 'context/user-context'

const defaultValues = { document_number: '', password: '' }

const Login = () => {
  const [loginDataError, setLoginDataError] = useState(false)

  const {
    control,
    handleSubmit,
    errors,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(LoginResolver),
    defaultValues
  })

  const router = useRouter()
  const { login } = useAuth()

  const onSubmitFormat = async ({ document_number, password }) => {
    try {
      const response = await login({
        document_number,
        password
      })

      if (!!router.query.return) {
        window.history.back()
      } else {
        if (checkIsInvestor(response)) {
          const isCompleted = await checkIsCompletedInvestor()
          if (isCompleted) {
            window.location.replace('/campanhas')
          } else {
            window.location.replace('/preencher-dados')
          }
        } else {
          window.location.replace('/minhas-indicacoes')
        }
      }

      setModalCampaing(true)
    } catch (error) {
      toast.error('Por favor, verifique seus dados de acesso e tente novamente!')
      setLoginDataError(true)
    }
  }

  return (
    <Column alignItems='center' width='100%' paddingTop='32vh'>
      <NextSeo title='Kavod Lending - Login' description='KavodLending | Peer to Peer lending' />
      <form onSubmit={handleSubmit(onSubmitFormat)}>
        <Text variant='h3'>Entrar</Text>
        <NumberInput
          maxwidth={384}
          mb='40px !important'
          mt={4}
          id='document_number'
          name='document_number'
          inputType='cpf'
          control={control}
          error={errors.document_number || loginDataError}
        />
        <PasswordInput
          mb='40px !important'
          id='password'
          name='password'
          control={control}
          error={errors.password || loginDataError}
        />

        <Button maxwidth={384} width='100%' mb='40px !important' type='submit' loader={isSubmitting}>
          Entrar
        </Button>
        <Column>
          <Row alignItems='baseline' mb='8px'>
            <Text variant='body2' style={{ color: 'gray.800' }}>
              Ainda não possui uma conta?
            </Text>
            <Link fontSize={14} marginLeft={1} href='/cadastrar'>
              Cadastre-se
            </Link>
          </Row>
          <Row>
            <Text variant='body2' style={{ color: 'gray.800' }}>
              Esqueceu sua senha?
            </Text>
            <Link fontSize={14} marginLeft={1} href='/recuperar-senha'>
              Clique aqui para recuperá-la
            </Link>
          </Row>
        </Column>
        <DevTool control={control} />
      </form>
    </Column>
  )
}

export default Login
